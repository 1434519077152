import React, { useEffect, useState } from 'react';

interface RenderOnScrollProps {
  id: string;
  children: React.ReactNode | React.ReactNode[];
  loader?: React.ReactNode;
}

export const RenderOnScroll: React.FC<RenderOnScrollProps> = ({
  id,
  children,
  loader = null,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, 1000);
          observer.disconnect();
        }
      },
      { rootMargin: '0px 0px 100px 0px' }
    );
    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [id]);

  return (
    <div id={id} className="render-on-scroll">
      {isVisible ? children : loader}
    </div>
  );
};

export default RenderOnScroll;
