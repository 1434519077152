import { ESIData } from 'src/components/page-esi-wrapper/page-esi-wrapper.component';
import { ValidationResult, ValidationOutcomes } from '.';
import {
  hasPropertyAndEquals,
  isObjectEmpty,
  replaceString,
} from '../utils/aem-utils/utils';

const getSearchESI = (
  version: string,
  destination: string | null = null,
  bookingType: string | null = null
): string => {
  let esiString: string = '';
  const esiData = ESIData(false, destination, bookingType, 'en-GB');
  switch (version) {
    case 'V1':
      esiString = esiData.VHOLS.search.esi;
      break;
    case 'V2':
      esiString = esiData.VAA.search.esi;
      break;
    case 'V3':
      esiString = esiData.VAA.search.esi;
      break;
    default:
      esiString = '';
      break;
  }
  return esiString;
};

const ValidateSearchPanelComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (isObjectEmpty(props)) {
    vr.messages.push('Search Panel is empty');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  if (!props.hasOwnProperty('version')) {
    vr.messages.push('Search Panel is missing version property');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.componentProps = props;
  const { version, destination, bookingType } = props || {};

  let searchESI = getSearchESI(version, destination, bookingType);

  if (searchESI === '') {
    vr.messages.push('Search Panel version is invalid');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // V1 - Virgin Holidays
  if (props['version'] === 'V1') {
    vr.componentProps['displayButton'] = true;
  }

  // V2 - Virgin Atlantic
  if (props['version'] === 'V2') {
    if (hasPropertyAndEquals(props, 'open', false)) {
      searchESI = replaceString(searchESI, 'open=true', 'open=false');
    }
    if (hasPropertyAndEquals(props, 'closable', true)) {
      searchESI = replaceString(searchESI, 'closable=false', 'closable=true');
    }

    vr.componentProps['displayButton'] = false;
  }

  // V3 - Virgin Atlantic
  if (props['version'] === 'V3') {
    const options_type_mapping: { [key: string]: string } = {
      FLIGHT_ONLY: 'flight_only',
      REWARD_FLIGHT: 'reward_flight',
      HOLIDAY: 'holiday',
      FLYDRIVE: 'flydrive',
      MULTICENTRE: 'multicentre',
    };

    const mapping: { [key: string]: string } = {
      FLIGHT_ONLY: 'flightOnly',
      REWARD_FLIGHT: 'rewardFlight',
      HOLIDAY: 'holiday',
      FLYDRIVE: 'flyDrive',
      MULTICENTRE: 'multiCentre',
    };

    const { openState, hideToggle, optionsType, closable } = props || {};

    const stateParams = [];

    stateParams.push(`state:open=${openState}`);
    stateParams.push(`state:hideToggle=${hideToggle}`);
    stateParams.push(`state:closable=${closable}`);

    const joinedStateParams = stateParams.join('&');
    const criteriaParams = [];

    criteriaParams.push(`criteria:type=${options_type_mapping[optionsType]}`);

    if (optionsType === 'FLIGHT_ONLY' || optionsType === 'REWARD_FLIGHT') {
      const obj = mapping[optionsType];
      const { origin, destination } = props[obj];
      if (origin) {
        criteriaParams.push(`criteria:origin=${origin}`);
      }
      if (destination) {
        criteriaParams.push(`criteria:destination=${destination}`);
      }
      // This is to make sure search panel shows return flights rather than one way.
      if (destination && origin) {
        criteriaParams.push(`criteria:origin=${destination}`);
        criteriaParams.push(`criteria:destination=${origin}`);
      }
    }

    if (
      optionsType === 'HOLIDAY' ||
      optionsType === 'FLYDRIVE' ||
      optionsType === 'MULTICENTRE'
    ) {
      const obj = mapping[optionsType];
      const { location, gateway } = props[obj];
      if (location) {
        criteriaParams.push(`criteria:location=${location}`);
      }
      if (gateway) {
        criteriaParams.push(`criteria:gateway=${gateway}`);
      }
    }

    const joinedCriteriaParams = criteriaParams.join('&');

    searchESI = `${searchESI.replace(
      'PLACEHOLDER',
      `${joinedStateParams}&${joinedCriteriaParams}`
    )}`;
  }

  vr.componentProps['ESIString'] = searchESI;

  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateSearchPanelComponentProps;
