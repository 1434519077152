import { AnimationDirectionEnum, BarChartComponent, BarGapEnum } from '@vaa-component-lib/component.molecule.bar-chart';
import styles from './climate-chart.component.module.less';
import RenderOnScroll from '../render-on-scroll/render-on-scroll.component';
import { SkeletonLoaderComponent } from '@vaa-component-lib/component.atom.skeleton-loader';

type Month = 'jan' | 'feb' | 'mar' | 'apr' | 'may' | 'jun' | 'jul' | 'aug' | 'sep' | 'oct' | 'nov' | 'dec';

export type Months = {
    [key: string]: string;
}

interface ClimateChartProps {
    componentId?: string;
    title: string;
    temperature: Months;
    rainfall: Months;
    chartKey: {
        rainfall: string;
        temperature: string;
    }
}

const ClimateChartLoader = () => <SkeletonLoaderComponent loading={true} width='100%'><div style={{ height: '300px' }}></div></SkeletonLoaderComponent>;

const ClimateChartComponent = ({ componentId, temperature, rainfall, chartKey, title }: ClimateChartProps) => {

    const MONTH_ORDER: Month[] = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const DEFAULT_TEMP_YELLOW = '#FFD84E';
    const MAX_TEMP_RED = '#f44e44';
    const MIN_TEMP_BLUE = '#9ED5FD';
    const COMPONENT_ID = componentId || 'climate-chart-component';
    const RAINFALL_COLOUR = { top: '#0D6EFF', bottom: '#4B9BFF' };

    const monthLookUp: Months = {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December'
    };

    if (!temperature || !rainfall) {
        return null;
    }

    const sortByMonth = (a: any, b: any) => {
        return MONTH_ORDER.indexOf(a.month) - MONTH_ORDER.indexOf(b.month);
    };

    const tempColourPicker = (value: number) => {
        if (value < 0) {
            return { top: '#DFF8FF', bottom: MIN_TEMP_BLUE };
        } else if (value > 0 && value < 5) {
            return { top: '#FFD84E', bottom: DEFAULT_TEMP_YELLOW };
        } else if (value >= 5 && value < 10) {
            return { top: '#f4b944', bottom: DEFAULT_TEMP_YELLOW };
        } else if (value >= 10 && value < 15) {
            return { top: '#f49f44', bottom: DEFAULT_TEMP_YELLOW };
        } else if (value >= 15 && value < 20) {
            return { top: '#f47d44', bottom: DEFAULT_TEMP_YELLOW };
        } else if (value >= 20 && value < 25) {
            return { top: MAX_TEMP_RED, bottom: DEFAULT_TEMP_YELLOW };
        } else if (value >= 25 && value < 30) {
            return { top: MAX_TEMP_RED, bottom: DEFAULT_TEMP_YELLOW };
        } else if (value >= 30) {
            return { top: MAX_TEMP_RED, bottom: DEFAULT_TEMP_YELLOW };
        } else {
            return { top: '#FFD84E', bottom: DEFAULT_TEMP_YELLOW };
        }
    };

    const temperatureValues = Object.keys(temperature)
        .filter((key) => MONTH_ORDER.includes(key as Month))
        .map((key: string) => {
            return {
                month: key,
                label: {
                    postText: '°'
                },
                value: Number(temperature[key]),
                dataSet: 0,
                colour: tempColourPicker(Number(temperature[key])),
                tooltip: { preText: 'Temperature: ', postText: 'C' },
                ariaLabel: `Temperature: ${temperature[key]}°C in ${monthLookUp[key]}`
            };
        }
        ).sort(sortByMonth);

    const rainfallValues = Object.keys(rainfall)
        .filter((key: string) => MONTH_ORDER.includes(key as Month))
        .map((key: any, index: number) => {
            return {
                month: key,
                value: Number(rainfall[key]),
                dataSet: 1,
                colour: RAINFALL_COLOUR,
                tooltip: { preText: 'Rainfall: ', postText: 'mm' },
                ariaLabel: `Rainfall: ${rainfall[key]}mm in ${monthLookUp[key]}`
            };
        }).sort(sortByMonth);

    const combinedValues = [...temperatureValues, ...rainfallValues].sort(sortByMonth);

    const climateChartConfig = {
        heading: title,
        height: 300,
        scrollOnMobile: true,
        delay: 100,
        testId: 'climate-chart',
        className: 'climate-chart',
        animation: {
            direction: 'inside' as AnimationDirectionEnum,
            delay: 0
        },
        tooltip: {
            display: true
        },
        bars: {
            gap: BarGapEnum.Small,
            alternateGapWidth: true,
            offset: {
                top: 10
            },
            values: combinedValues,
        },
        xAxis: {
            values: [
                { label: 'Jan' },
                { label: 'Feb' },
                { label: 'Mar' },
                { label: 'Apr' },
                { label: 'May' },
                { label: 'Jun' },
                { label: 'Jul' },
                { label: 'Aug' },
                { label: 'Sep' },
                { label: 'Oct' },
                { label: 'Nov' },
                { label: 'Dec' }
            ]
        },
        chartKeys: [
            {
                text: chartKey.temperature,
                colour: {
                    top: MAX_TEMP_RED,
                    middle: DEFAULT_TEMP_YELLOW,
                    bottom: MIN_TEMP_BLUE
                }
            },
            {
                text: chartKey.rainfall,
                colour: RAINFALL_COLOUR
            }
        ]
    };

    return (
        <RenderOnScroll id={COMPONENT_ID} loader={<ClimateChartLoader />}>
            <div className={styles['climate-chart']}>
                <BarChartComponent {...climateChartConfig} />
            </div>
        </RenderOnScroll>
    );
};

export default ClimateChartComponent;
