import { isObjectEmpty } from 'src/utils/aem-utils/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import { convertToAbsoluteLinkUrl } from 'src/utils';

const isButtonPropValid = (buttonProps: any): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (isObjectEmpty(buttonProps)) {
    vr.messages.push('Button props are empty. {}');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  if (buttonProps.hasOwnProperty('href')) {
    if (buttonProps.href.startsWith('www.'))
      buttonProps.href = 'https://' + buttonProps.href;
    buttonProps.href = convertToAbsoluteLinkUrl(buttonProps.href);
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = buttonProps;
  return vr;
};

export default isButtonPropValid;
