import React, { useMemo } from 'react';
import ContentCardComponent, {
  ParentComponent,
} from '../content-card/content-card.component';
import TableCard from '../table-card/table-card.component';
import { useMediaBreakpoint, ScreenSize } from 'src/hooks/use-media-breakpoint';
import styles from './content-card-container.component.module.less';

interface ContentCardContainerComponentProps {
  cards: any[];
}

function renderCardComponent(cardProps: any, cardType: string, index: number) {
  switch (cardType) {
    case 'content-card':
      return (
        <ContentCardComponent
          key={`Carousel--child--ccc--${index}`}
          parentComponent={ParentComponent.ContentCardContainer}
          {...cardProps}
        />
      );
    case 'table-card':
      return (
        <TableCard key={`Carousel--child--tcc--${index}`} {...cardProps} />
      );
    default:
      return null;
  }
}

const ContentCardContainerComponent: React.FC<
  ContentCardContainerComponentProps
> = ({ cards = [] }) => {
  const currentBreakpoint = useMediaBreakpoint();

  const perpetualStyles = useMemo(
    () => ({
      columnGap: '32px',
      rowGap: currentBreakpoint === ScreenSize.Desktop ? '0' : '32px',
    }),
    [currentBreakpoint]
  );

  const dynamicStyles = useMemo(
    () => ({
      display: 'flex' as const,
      flexWrap: 'wrap' as const,
      justifyContent: 'space-between' as const,
    }),
    []
  );

  const containerStyles = useMemo(() => {
    return {
      ...perpetualStyles,
      ...dynamicStyles,
    };
  }, [perpetualStyles, dynamicStyles]);

  return (
    <div className={styles['content-card-container']} style={containerStyles}>
      {cards.map((card, index) => {
        const props = card.componentProps;
        if (!props) return null;

        const cardType = props['sling:resourceType'].split('/').pop();

        props.orientation = cards.length === 1 ? 'landscape' : 'portrait';

        return (
          <div
            key={`card-component-${index}`}
            className={`${styles['card-wrapper']} ${
              styles[`cards-${cards.length}`]
            }`}
          >
            {renderCardComponent(props, cardType, index)}
          </div>
        );
      })}
    </div>
  );
};

export default ContentCardContainerComponent;
