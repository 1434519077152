export enum Element {
  SPAN = 'span',
  DIV = 'div',
}

interface Props {
  htmlString: string;
  id?: string;
  style?: React.CSSProperties;
  element?: Element;
}

const HTMLRenderer = ({
  htmlString,
  style,
  id,
  element = Element.SPAN,
}: Props) => {
  const createMarkup = () => {
    return { __html: htmlString };
  };

  const El = element;

  return <El id={id} style={style} dangerouslySetInnerHTML={createMarkup()} />;
};

export default HTMLRenderer;
