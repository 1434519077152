import {
  GalleryCardComponent as GalleryCard,
  GalleryCardProps,
} from '@vaa-component-lib/component.molecule.gallery-card';
import styles from './gallery-card.component.module.less';
import { useState, useEffect } from 'react';
import { checkCardBadge } from 'src/utils';
import classNames from 'classnames';
import { appendDomainToImageRenditions } from '../../utils/component-utils/utils';

interface GalleryCardComponentProps extends GalleryCardProps {
  cardType?: string;
  textIsRich?: boolean;
  halfCardHeight?: boolean;
  'sling:resourceType'?: string;
}

export default function GalleryCardComponent({
  ...props
}: GalleryCardComponentProps) {
  const [isMounted, setIsMounted] = useState(false);
  const [cardProps, setCardProps] = useState<any>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      checkCardBadge(props);
      if (
        props.hasOwnProperty('sling:resourceType') &&
        props['sling:resourceType']
      )
        delete props['sling:resourceType'];

      const { cardType, textIsRich, image, ...rest } = props;
      const updatedImages = appendDomainToImageRenditions(image);
      setCardProps({ ...rest, image: updatedImages });
      setIsMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  return (
    isMounted &&
    cardProps && (
      <div
        className={classNames(styles['gallery-card'], {
          [styles['gallery-card__half']]: cardProps.halfCardHeight,
        })}
      >
        <GalleryCard {...cardProps} />
      </div>
    )
  );
}
