import _ from 'cypress/types/lodash';
import { debugError } from '..';
import {
  stripPath,
  validateComponentProps,
  getColumnClassInfo,
  cleanHeadlessData,
  isObjectEmpty,
} from './utils';
import { ValidationOutcomes, ValidationResult } from 'src/validation';

let flatComponentsArray: any[] = [];

// Extracts the items(components) from the AEM container and adds to the array of components (flatComponentsArray)
async function processContainerData(
  _containerName: string,
  containerData: any
) {
  const containerItems = containerData[':items'];
  const containerItemsOrder = containerData[':itemsOrder'];

  for (const containerItemName of containerItemsOrder) {
    const containerItem = containerItems[containerItemName];
    const containerItemType = stripPath(containerItem[':type']) || 'unknown';
    const containerItemColumnClassNames =
      containerData.columnClassNames[containerItemName];

    if (containerItemType === 'container') {
      await processContainerData(containerItemName, containerItem);
    } else if (containerItemType === 'experience-fragment') {
      if (
        Object.hasOwn(containerItem, 'vaaData') &&
        Object.hasOwn(containerItem.vaaData, 'content')
      ) {
        const { content } = containerItem.vaaData;

        Object.keys(content).forEach((experienceFragmentItemKey) => {
          const experienceFragmentItemType =
            stripPath(
              content[experienceFragmentItemKey]['sling:resourceType']
            ) || 'unknown';

          if (experienceFragmentItemType !== 'unknown') {
            const experienceFragmentData: any = {};
            experienceFragmentData.props = content[experienceFragmentItemKey];
            experienceFragmentData.id = experienceFragmentItemKey;
            experienceFragmentData.name = experienceFragmentItemKey;
            experienceFragmentData.columnClassNames =
              'aem-GridColumn aem-GridColumn--default--12';
            validateComponent(
              experienceFragmentData,
              experienceFragmentItemType
            );
          }
        });
      }
    } else {
      // Item is a (storybook) component
      // check for supported types from the lookup file
      if (
        containerItem.hasOwnProperty('vaaData') &&
        !isObjectEmpty(containerItem.vaaData)
      ) {
        const containerItemData: any = {};
        containerItemData.props = containerItem.vaaData;
        containerItemData.id = containerItem.id;
        containerItemData.name = containerItemName;
        containerItemData.columnClassNames = containerItemColumnClassNames;

        validateComponent(containerItemData, containerItemType);
      }
    }
  }
}

/// Validate the component props
function validateComponent(componentData: any, componentType: string) {
  //TODO: Insert component based async external fetch logic here
  //! This is where the props are processed
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };
  vr = validateComponentProps(componentData.props, componentType);

  if (vr.result === ValidationOutcomes.FAILURE) {
    debugError(
      `${componentData.id} has failed validation and will not be rendered.`,
      vr.messages
    );
  }

  if (vr.result === ValidationOutcomes.UNKNOWN) {
    debugError(
      `${componentData.id} has unknown validation status and will not be rendered.`,
      vr.messages
    );
  }

  if (vr.result === ValidationOutcomes.FAILURE) {
    debugError(
      `${componentData.id} has failed validation and will not be rendered.`,
      vr.messages
    );
  }
  if (
    vr.result === ValidationOutcomes.SUCCESS ||
    vr.result === ValidationOutcomes.WARNING
  ) {
    const componentInfo = {
      isContainer: false,
      name: componentData.name,
      componentType: componentType,
      componentProps: vr.componentProps,
      gridInfo: getColumnClassInfo(componentData.columnClassNames),
    };

    flatComponentsArray.push(componentInfo);
  }
}

export const processData = async (aemServerData: any) => {
  flatComponentsArray = [];
  cleanHeadlessData(aemServerData);

  const rootItems = aemServerData[':items'].root[':items'];
  const rootItemsOrder = aemServerData[':items'].root[':itemsOrder'];

  for (const rootItemName of rootItemsOrder) {
    const rootItem = rootItems[rootItemName];
    const rootItemType = stripPath(rootItem[':type']);
    if ('container' === rootItemType) {
      if (flatComponentsArray.length > 0)
        flatComponentsArray.push({
          isContainer: false,
          name: `spacer-${Date.now()}`,
          componentType: 'spacer',
          componentProps: null,
          gridInfo: { width: 12, offset: 0 },
        });
      await processContainerData(rootItemName, rootItem);
    }
  }

  if (
    flatComponentsArray.length > 0 &&
    flatComponentsArray[flatComponentsArray.length - 1].componentType !==
      'spacer'
  )
    // last spacer for the page
    flatComponentsArray.push({
      isContainer: false,
      name: `spacer-${Date.now()}`,
      componentType: 'spacer',
      componentProps: null,
      gridInfo: { width: 12, offset: 0 },
    });

  // console.log('Flattened Components', flatComponentsArray);
  return [
    {
      isContainer: true,
      name: 'AEM Root Container',
      columnCount: 12,
      gridInfo: { width: 12, offset: 0 },
      components: flatComponentsArray,
    },
  ];
};
