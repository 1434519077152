import { isObjectEmpty } from 'src/utils/aem-utils/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import { convertToAbsoluteLinkUrl } from 'src/utils';

const ValidateTextLockupComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!props || isObjectEmpty(props)) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('TextLockup - No props provided. {}');
    return vr;
  }

  if (!props.children && !props.heading && !props.subheading) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push(
      'TextLockup - No valid content. Please provide children, heading or subheading.'
    );
    return vr;
  }

  if (Object.hasOwn(props, 'buttons') && props.buttons.length > 0) {
    props.buttons.forEach((button: any) => {
      if (button.hasOwnProperty('href')) {
        if (button.href.startsWith('www.'))
          button.href = 'https://' + button.href;
        button.href = convertToAbsoluteLinkUrl(button.href);
      }
    });
  }

  vr.componentProps = props;
  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateTextLockupComponentProps;
