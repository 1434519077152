import { isObjectEmpty, objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';

const isBadgePropValid = (badgeProps: any): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (!badgeProps || isObjectEmpty(badgeProps)) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('Badge props are empty. {}');
    return vr;
  }

  if (!objectHasFields(badgeProps, ['label'])) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('Badge is missing label');
    return vr;
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = badgeProps;
  return vr;
};

export default isBadgePropValid;
