import { getStringBetween } from '..';
import { Logger } from './logging';

/**
 * The special offers response from the API
 */
interface SpecialOffersResponse {
  values: {
    minPricePerPerson: number;
  };
}


/**
 * 
 * @param pageContent The page content to be processed
 */
export async function priceGrabPageContent(pageContent: any[]) {
  if (!Array.isArray(pageContent)) {
    new Logger().error('Invalid page content provided. Expected an array.');
    return pageContent;
  }

  let pageJSON = JSON.stringify(pageContent);
  const priceGrabDefinitions = pageJSON.match(/\${.*?}/g) as string[] || null;

  if (!priceGrabDefinitions || priceGrabDefinitions.length === 0) {
    return pageContent;
  }

  const promises: Promise<SpecialOffersResponse>[] = priceGrabDefinitions.map((priceGrabDefinition) => {
    const query = getStringBetween_pg(cleanupDefinition(priceGrabDefinition));
    return fetchSpecialOffers(query);
  });

  const results = await Promise.allSettled(promises);

  results.forEach((result, index) => {
    const priceGrabDefinition = priceGrabDefinitions[index];
    const query = getStringBetween_pg(priceGrabDefinition);

    if (result.status === 'fulfilled') {
      const resolvedValue = result.value?.values?.minPricePerPerson || 0;
      const priceTag = resolvedValue === 0 
        ? '' 
        : priceGrabDefinition
            .replace(`__priceGrabber?${query}__`, Math.round(resolvedValue).toString())
            .replace('${', '')
            .replace('}', '');
      pageJSON = pageJSON.replace(priceGrabDefinition, priceTag);
    } else {
      new Logger().error(`Error processing price grabber definition: ${priceGrabDefinition} - ${result.reason}`);
    }
  });

  return JSON.parse(pageJSON);
}

/**
 * 
 * @param queryString Fetches the special offers from the API using the query string
 */
async function fetchSpecialOffers(queryString: string) : Promise<SpecialOffersResponse> {

  if (queryString.charAt(0) === '?') queryString = queryString.substring(1);

  new Logger().info(`Fetching special offers with query string: ${queryString}`);

  // FIXME - Technical debt - This URL should be in a config file
  const specialOffersUrl = `http://vsolpublic.grn.virginholidays.co.uk/special-offers-api/special-offer/search/lowest/minPricePerPerson?${queryString}`
  
  // set a default value
  let data: SpecialOffersResponse = { values: { minPricePerPerson: 0 } };

  try {
    const res = await fetch(specialOffersUrl);

    if (res.status === 200) {
      data = await res.json();
    } else {
      if (res.status === 404) {
        // API returns 404 if no results found (e.g. parent=a_wrong_parent_id)
        new Logger().error(
          `Special offers API returns 404 - (maybe parent parameter is not found or the dates are invalid): ${specialOffersUrl}`
        );
      } else {
        new Logger().error(
          `Error fetching special offers. Error (${res.status}): ${specialOffersUrl}`
        );
      }
    }
  } catch (e) {
    new Logger().error(`Error fetching special offers from ${specialOffersUrl}\r\n${e}`);
  }
  return data;
}

const getStringBetween_pg = (
  inputString: string,
  startString: string = '__priceGrabber?',
  endString: string = '__'
) =>
  getStringBetween(inputString, startString, endString).replace('||true', '');

const cleanupDefinition = (definition: string) =>
  definition.replace(/&amp;/g, '&');
