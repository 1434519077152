import {
  ContentCardComponent as ContentCard,
  ContentCardProps,
} from '@vaa-component-lib/component.molecule.content-card';
import styles from './content-card.component.module.less';
import classnames from 'classnames';
import { containsHTML } from 'src/utils/aem-utils/utils';
import HTMLRenderer from '../html-renderer/html-renderer.component';
import { useState, useEffect } from 'react';
import { BadgeProps } from '@vaa-component-lib/component.atom.badge';
import { checkCardBadge, hardRedirectTo } from 'src/utils';
import { appendDomainToImageRenditions } from '../../utils/component-utils/utils';

export enum ParentComponent {
  None = 'none',
  ContentCardContainer = 'content-card-container',
  Carousel = 'carousel',
}

interface ContentCardBadgeProps extends BadgeProps {
  hideLabel?: string;
}
interface ContentCardComponentProps extends ContentCardProps {
  parentComponent?: ParentComponent;
  cardType?: string;
  'sling:resourceType'?: string;
  badge?: ContentCardBadgeProps | any;
  textIsRich?: boolean;
}

export default function ContentCardComponent({
  parentComponent = ParentComponent.None,
  ...props
}: ContentCardComponentProps) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') setIsMounted(true);
  }, []);
  const href = props?.button?.href;
  const hasLink = href && href.length > 0 ? true : false;
  const hasImage =
    props?.image?.url && props?.image?.url.length > 0 ? true : false;

  const handleOnClick = (e: any) => {
    const className = e.target.className;
    const isImage = className.indexOf('content-card-module_image') > -1;

    if (isImage) {
      if (href && hasLink && typeof window !== 'undefined') {
        hardRedirectTo(href);
      }
    }
  };

  //? These props are not used in the ContentCard component and should be removed
  // props.cardType
  // props['sling:resourceType']
  // props.textIsRich

  if (props.hasOwnProperty('cardType') && props.cardType) delete props.cardType;
  if (props.hasOwnProperty('sling:resourceType') && props['sling:resourceType'])
    delete props['sling:resourceType'];
  if (props.hasOwnProperty('textIsRich') && props.textIsRich)
    delete props.textIsRich;

  checkCardBadge(props);

  if (props.image) {
    props.image = appendDomainToImageRenditions(props.image);
  }

  // If the text prop contains HTML, render it using the HTMLRenderer component
  if (
    props.hasOwnProperty('text') &&
    props.text &&
    containsHTML(props.text as string)
  ) {
    props.text = HTMLRenderer({ htmlString: props.text as string }) as any;
  }

  return (
    isMounted && (
      <div
        className={classnames(styles['content-card'], {
          [styles['content-card__parent--carousel']]:
            parentComponent === ParentComponent.Carousel,
          [styles['content-card__link-image']]: hasLink && hasImage,
        })}
        onClick={handleOnClick}
      >
        <ContentCard {...props} />
      </div>
    )
  );
}
